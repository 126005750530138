import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from '../../store'
import { actionCreators as toursActions } from '../../store/Tours'
import { Grid, Row, Col, SectionTitle, ContainerWithPadding, EntitiesPaginatedModel, SeoLen, LabelFormField, FormFieldWrapper, apiCallWrapperNoDispatch, Popup, FormFieldLabel, Tooltip } from 'traveldesk-ui'
import { LoadingSection } from '../../components/LoadingSection'
import parseId from '../../services/parseId'
import { TextFormField, SelectFormFieldController } from 'traveldesk-ui'
import { DirectionTypesOptions, TourDirectionModel, TourManageModel } from '../../models/TourManageModel'
import { slugify } from 'transliteration'
import { Controller, useForm } from 'react-hook-form'
import { nameof } from 'traveldesk-ui'
import { useLanguagesManageTable } from '../../effects/useLanguages'
import VideoDisplay from '../../components/Video/Video'
import { MediaSelector } from 'traveldesk-ui'
import { Photo } from 'traveldesk-ui'
import { Link } from 'react-router-dom'
import { OfferManageTourModel } from '../../models/Offer'
import { Buttons } from 'traveldesk-ui'
import Cancellations from './Cancellations'
import CancellationPolicy from './../../models/CancellationPolicy'
import { useSupplier } from './../../effects/useSupplier'
import NoDefaultValue from './../../components/Ui/NoDefaultValue'
import { IdFormField, ToggleButtonsSmallFieldController, WysiwygFieldController } from 'traveldesk-ui'
import { DataTableColumnModel, DataTableModel, DataTableModelCustomAction, DataTable } from 'traveldesk-ui'
import { actionCreators as toursCategoriesActions } from '../../store/ToursCategories'
import { actionCreators as toursRecommendedForActions } from '../../store/ToursRecommendedFor'
import { actionCreators as citiesActions } from '../../store/Cities'
import { MapsLocation } from 'traveldesk-ui'
import configureStore from '../../store/configureStore'
import { MapsLocationPicker } from 'traveldesk-ui'
import MapsPlaceIdPicker from '../../components/MapsLocationPicker/MapsPlaceIdPicker'
import Rating from '../../models/Rating'
import TourFaqModel from '../../models/TourFaqModel'
import TourFormFaq from './TourFormFaq'
import TourItineraryForm from './TourItinerary'
import ItineraryModel from '../../models/ItineraryModel'
import TripAdvisorLocationIdPicker from '../../components/MapsLocationPicker/TripAdvisorLocationIdPicker'
import ItineraryDesigner from '../../components/ItineraryDesigner/ItineraryDesigner'
import { GLOBAL_SETTINGS } from '../..'
import { error } from 'console'
import TourFormDirections from './TourFormDirections'

function getTripAdvisorLocationIdFromUrl(url: string) {
    var regex = /d(\d+)-/;
    var match = url.match(regex);

    if (match && match.length > 1) {
        return match[1];
    } else {
        return null;
    }
}
interface Props extends RouteComponentProps<{ id: string }> { }
enum TourFormTab {
    Basic, Location, Terms, Faq, Itinerary, Media, Seo, Tickets
}
const TourForm = (props: Props) => {
    const dispatch = useDispatch()
    const [iteneraryEdit, setIteneraryEdit] = React.useState<ItineraryModel | undefined>(undefined)
    const [tab, setTab] = React.useState<TourFormTab>(TourFormTab.Basic)
    const accountSettings = useSelector((state: ApplicationState) => state.account.settings)
    const supplier = useSupplier()
    const languages = useLanguagesManageTable()
    const citiesOptions = useSelector((state: ApplicationState) => state.cities.citiesOptions)
    const toursCategoriesOptions = useSelector((state: ApplicationState) => state.toursCategories.options)
    const toursRecommendedForOptions = useSelector((state: ApplicationState) => state.toursRecommendedFor.options)
    React.useEffect(() => {
        dispatch(toursCategoriesActions.requestOptions())
        dispatch(toursRecommendedForActions.requestOptions())
        dispatch(citiesActions.requestCitiesOptions())
        return () => {
            // dispatch(toursActions.manageTourClear())
        }
    }, [])
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        getValues,
        setValue,
        watch,
        reset,
        setError,
    } = useForm()
    const tour = useSelector((state: ApplicationState) => state.tours.tour)
    const videoUrl = watch('videoUrl', tour?.videoUrl ?? '')
    const nameDisplay = watch('nameDisplay', tour?.nameDisplay ?? '')
    const url = watch('url', tour?.url ?? '')
    const isDefaultNotesToClientsPostBooking = watch(
        'isDefaultNotesToClientsPostBooking',
        tour?.isDefaultNotesToClientsPostBooking ?? true,
    )
    const isDefaultFaq = watch('isDefaultFaq', tour?.isDefaultFaq ?? true)
    const isDefaultTermsAndConditions = watch('isDefaultTermaAndConditions', tour?.isDefaultTermsAndConditions ?? true)
    const isDefaultNotesToClientPostBooking = watch(
        'isDefaultNotesToClientPostBooking',
        tour?.isDefaultNotesToClientsPostBooking ?? true,
    )
    const [isAutoUrl, setIsAutoUrl] = React.useState(slugify(nameDisplay) == url)
    React.useEffect(() => {
        const nameDisplayUrl = slugify(nameDisplay)
        setIsAutoUrl(nameDisplayUrl == url || url == '')
    }, [url])
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const [photos, setPhotos] = React.useState(tour?.photos ?? [])
    const [faq, setFaq] = React.useState(tour?.faqs ?? [])
    const [directions, setDirections] = React.useState(tour?.directions ?? [])
    const [itineraries, setItineraries] = React.useState(tour?.itineraries ?? [])
    const [location, setLocation] = React.useState<MapsLocation>(new MapsLocation(tour?.locationAddress ?? "", tour?.locationLatitude, tour?.locationLongitude))
    const [showDeletedOffers, setShowDeletedOffers] = React.useState<boolean>(false)
    const offers = React.useMemo(() => {
        return (showDeletedOffers ? tour?.offers : tour?.offers.filter(x => !x.isDeleted)) ?? []
    }, [showDeletedOffers, tour])
    const [cancellation, setCancellation] = React.useState(
        (tour?.isDefaultCancellationPolicy ? supplier?.cancellationPolicy : tour?.cancellationPolicy) ?? [],
    )
    const [heroImageId, setHeroImageId] = React.useState(tour?.heroImageId)
    React.useEffect(() => {
        const id = parseId(props.match.params.id)
        if (typeof id == undefined) {
            props.history.push('/tours')
        }
        dispatch(toursActions.manageTour(id || 0, accountSettings))
    }, [props.match.params.id])
    React.useEffect(() => {
        setPhotos(tour?.photos ?? [])
        setFaq(tour?.faqs ?? [])
        setDirections(tour?.directions ?? [])
        setItineraries(tour?.itineraries ?? [])
        setHeroImageId(tour?.heroImageId)
        setLocation(new MapsLocation(tour?.locationAddress ?? "", tour?.locationLatitude, tour?.locationLongitude))
        setCancellation(
            (tour?.isDefaultCancellationPolicy ? supplier?.cancellationPolicy : tour?.cancellationPolicy) ??
            cancellation,
        )
        if (tour && supplier) {
            if (
                !supplier.defaultSettings.notesToClientPostBooking ||
                supplier.defaultSettings.notesToClientPostBooking.length == 0
            ) {
                tour.isDefaultNotesToClientsPostBooking = false
            }
            if (!supplier.defaultSettings.faq || supplier.defaultSettings.faq.length == 0) {
                tour.isDefaultFaq = false
            }
            if (
                !supplier.defaultSettings.termsAndConditions ||
                supplier.defaultSettings.termsAndConditions.length == 0
            ) {
                tour.isDefaultTermsAndConditions = false
            }
            if (!supplier.cancellationPolicy || supplier.cancellationPolicy.length == 0) {
                tour.isDefaultCancellationPolicy = false
            }
        }
        reset(tour)
    }, [tour, supplier])
    const onHeroImageChanged = (photo: Photo) => {
        setHeroImageId(photo.id)
    }

    const onSubmit = (data: TourManageModel) => {
        let isOk = true
        // if (!data.cityId) {
        //     setError("cityId", { type: "custom", message: "City is required" })
        //     isOk = false;
        // }
        if (isOk) {
            data.heroImageId = heroImageId
            data.photos = photos
            data.faqs = faq
            data.directions = directions
            data.itineraries = itineraries
            data.cancellationPolicy = data.isDefaultCancellationPolicy ? [] : cancellation
            data.locationAddress = location.address
            data.locationLatitude = location.lat
            data.locationLongitude = location.lng
            // console.log(data)
            dispatch(toursActions.saveTour(data))
        }
    }
    const onSetLocation = (location: MapsLocation) => {
        setLocation(location)
    }

    const onSelectPhotos = (newPhotos: Photo[]) => {
        setPhotos(photos.concat(newPhotos))
    }
    const onDeletePhoto = (photo: Photo) => {
        setPhotos(photos.filter((x) => x.id != photo.id))
    }
    const handleFaqChanges = (faq: TourFaqModel[]) => {
        setFaq(faq)
    }
    const handleTourDirectionsChange = (directions: TourDirectionModel[]) => {
        setDirections(directions)
    }
    const toggleShowDeletedOffers = () => {
        setShowDeletedOffers(!showDeletedOffers)
    }
    const initOffersDataTable = () => {
        const columns = [
            new DataTableColumnModel({
                displayName: Localization.getString('Name'),
                fieldName: 'name',
                width: 200,
                customRenderer: (offer: OfferManageTourModel) => `${offer.nameDisplay} (${offer.nameInternal})`,
            }),
            new DataTableColumnModel({
                displayName: Localization.getString('Per adult'),
                fieldName: '',
                customRenderer: (offer: OfferManageTourModel) => offer.validPeriod?.pricePerAdultRecUsd ?? '',
            }),
            new DataTableColumnModel({
                displayName: Localization.getString('Per child'),
                fieldName: '',
                customRenderer: (offer: OfferManageTourModel) => offer.validPeriod?.pricePerChildRecUsd ?? '',
            }),
            new DataTableColumnModel({
                displayName: Localization.getString('Per trip'),
                fieldName: '',
                customRenderer: (offer: OfferManageTourModel) => offer.validPeriod?.pricePerTripRecUsd ?? '',
            }),
            new DataTableColumnModel({
                displayName: Localization.getString('Valid Until'),
                fieldName: '',
                customRenderer: (offer: OfferManageTourModel) =>
                    offer.validPeriod?.periodEnd?.format('DD.MM.YYYY') ?? Localization.getString('Not specified'),
            }),
            new DataTableColumnModel({
                displayName: '',
                fieldName: '',
                customRenderer: (offer: OfferManageTourModel) => (
                    <Link to={`/tours/${tour?.id ?? 'add'}/${offer.id}`}>
                        <i className="fa fa-pencil" />
                    </Link>
                ),
            }),
        ]
        return new DataTableModel({
            columns,
            name: Localization.getString('Tour Tickets'),
            fileName: 'tourtickets.xls',
            headerGroups: [[
                { Title: '', ColSpan: 1 },
                { Title: Localization.getString('Published Rate'), ColSpan: 3 },
                { Title: '', ColSpan: 2 },
            ]],
            customRowClassFunction: (obj: OfferManageTourModel) => obj.isDeleted ? "cancelled" : "",
            customActions: [
                new DataTableModelCustomAction(
                    () => { },
                    () => (
                        <Link to={`/tours/${tour?.id}/add`}>
                            <i className="fa fa-plus" />
                        </Link>
                    ),
                ),
                new DataTableModelCustomAction(
                    () => { },
                    () => (
                        <i title={showDeletedOffers ? "Shows deleted offers" : "Hides deleted offers"} onClick={toggleShowDeletedOffers} className={`fa fa-trash clickable${showDeletedOffers ? " active" : ""}`} />
                    )
                )
            ],
        })
    }
    const onCloseItineraryForm = () => {
        setIteneraryEdit(undefined)
    }
    const onAddEditItinerary = (itinerary: ItineraryModel) => {
        setIteneraryEdit(itinerary)
    }
    const onChangeItinerary = (itinerary: ItineraryModel) => {
        if (!itinerary.id) {
            setItineraries(itineraries.concat([itinerary]))
        }
        else {
            setItineraries(itineraries.map(x => x.index == itinerary.index ? itinerary : x))
        }
        onCloseItineraryForm()
    }
    const seoPageTitle = watch('seoPageTitle')
    const seoPageDescr = watch('seoPageDescription')
    const seo = watch('seo')
    const googlePlacesRating = watch('googlePlacesRating')
    const googlePlacesTotalRatings = watch('googlePlacesTotalRatings')
    const tripAdvisorRating = watch('tripAdvisorRating')
    const tripAdvisorTotalRatings = watch('tripAdvisorTotalRatings')
    const nameInternalError = errors.nameInternal ? errors.nameInternal.message : "";
    const nameDisplayError = errors.nameDisplay ? errors.nameDisplay.message : ''
    const urlError = errors.url ? errors.url.message : ''
    const languageError = errors.languageId ? errors.languageId.message : ''
    const tourCategoriesError = errors.toursCategories ? errors.toursCategories.message : ''
    const recommendedForError = errors.recommendedFor ? errors.recommendedFor.message : ''
    const basicTabErrors = `${nameInternalError}|${nameDisplayError}|${urlError}|${languageError}|${tourCategoriesError}|${recommendedForError}`
        .split('|').filter(x => x && x.length > 0)
    const isNoMedia = !photos || photos.length == 0
    const isNoHeroImage = !heroImageId
    const mediaWarning = isNoMedia ? "You haven't selected any photos" : isNoHeroImage ? "Hero image is not selected" : ""
    const tabIconStyle = { position: "absolute", marginLeft: "-5px" } as React.CSSProperties
    return (
        <div className="tabs-wrapper is-vertical">
            <div className="tab-head">
                <Link to="/tours"><div className='tab-head-item'>Back to tours</div></Link>
                <div
                    onClick={() => setTab(TourFormTab.Basic)}
                    className={`tab-head-item clickable ${tab == TourFormTab.Basic ? "is-active" : ""}`}>
                    Basic details
                    {basicTabErrors.length > 0 ? <span style={tabIconStyle} ><Tooltip richText={<React.Fragment>{basicTabErrors.map(x => <p>{x}</p>)}</React.Fragment>}><i className="fa fa-ban error" /></Tooltip></span> : null}
                </div>
                <div
                    onClick={() => setTab(TourFormTab.Location)}
                    className={`tab-head-item clickable 
                        ${tab == TourFormTab.Location ? "is-active" : ""}`}
                >
                    Location details
                </div>
                <div onClick={() => setTab(TourFormTab.Terms)} className={`tab-head-item clickable ${tab == TourFormTab.Terms ? "is-active" : ""}`}>Terms & Conditions</div>
                <div
                    onClick={() => setTab(TourFormTab.Media)}
                    className={`tab-head-item clickable ${tab == TourFormTab.Media ? "is-active" : ""}`}
                >
                    Media
                    {mediaWarning && <span style={tabIconStyle}><Tooltip text={mediaWarning}><i className="fa fa-warning warning" /></Tooltip></span>}
                </div>
                <div onClick={() => setTab(TourFormTab.Itinerary)} className={`tab-head-item clickable ${tab == TourFormTab.Itinerary ? "is-active" : ""}`}>Itinerary</div>
                <div onClick={() => setTab(TourFormTab.Faq)} className={`tab-head-item clickable ${tab == TourFormTab.Faq ? "is-active" : ""}`}>FAQ</div>
                <div onClick={() => setTab(TourFormTab.Seo)} className={`tab-head-item clickable ${tab == TourFormTab.Seo ? "is-active" : ""}`}>SEO</div>
                <div onClick={() => setTab(TourFormTab.Tickets)} className={`tab-head-item clickable ${tab == TourFormTab.Tickets ? "is-active" : ""}`}>Tickets</div>
            </div>
            <div className="tab-content-wrap">

                {tour ? (
                    <React.Fragment>
                        {/* <SectionTitle
                            title={tour.id ? Localization.getString('Edit tour') : Localization.getString('Add Tour')}
                        />
                        <Link className="a-back-to-list" to="/tours">
                            {Localization.getString('Back to tours list')}
                        </Link> */}
                        <form style={{ width: "100%", backgroundColor: "white" }} onSubmit={handleSubmit(onSubmit)}>
                            <div className={`tab-content-block ${tab == TourFormTab.Basic ? "is-active" : ""}`} data-tab="tab-v01">
                                <Row>
                                    <Col type="50">
                                        <IdFormField control={control} />
                                        <TextFormField
                                            label={Localization.getString('Tour Code')}
                                            placeholder={Localization.getString('AD CITY TOUR')}
                                            subLabel={Localization.getString('Internal name of your tour')}
                                            isRequired
                                            error={nameInternalError}
                                            // defaultValue={tour.nameInternal}
                                            name={nameof<TourManageModel>('nameInternal')}
                                            register={register}
                                            options={{ required: Localization.getString('Tour code is required') }}
                                        />
                                        <TextFormField
                                            label={Localization.getString('Tour Display Name')}
                                            placeholder={Localization.getString('Abu Dhabi City Tour')}
                                            subLabel={Localization.getString('Public facing name of the tour')}
                                            isRequired={true}
                                            error={nameDisplayError}
                                            // defaultValue={tour.nameDisplay}
                                            name={nameof<TourManageModel>('nameDisplay')}
                                            register={register}
                                            options={{
                                                required: Localization.getString('Tour display name is required'),
                                                onChange: (e: any) => {
                                                    if (isAutoUrl) setValue(nameof<TourManageModel>('url'), slugify(e.target.value))
                                                    return e.target.value
                                                }
                                            }}
                                        />
                                        <TextFormField
                                            // defaultValue={tour.url}
                                            label={Localization.getString('Url')}
                                            subLabel={`traveldesk.me/${url}`}
                                            isRequired
                                            register={register}
                                            options={{ required: Localization.getString('Url is required') }}
                                            name={nameof<TourManageModel>('url')}
                                            error={urlError}
                                        />
                                        <SelectFormFieldController
                                            name={nameof<TourManageModel>('languageId')}
                                            isRequired
                                            label={Localization.getString('Language')}
                                            isNotSearchable={true}
                                            error={languageError}
                                            options={languages.languagesOptions}
                                            control={control}
                                            rules={{
                                                validate: (value: any) => {
                                                    return value && value > 0 ? undefined : "Language is required"
                                                }
                                            }}
                                        />
                                        <FormFieldWrapper>
                                            <Controller
                                                defaultValue={tour.isTopTour}
                                                render={({ field }) => {
                                                    return <>
                                                        <label>Is Top Tour</label>
                                                        <i style={{ fontSize: 30 }} className={`fa fa-star clickable ${field.value ? "warning" : ""}`} onClick={() => {
                                                            field.onChange(!field.value)
                                                        }} />
                                                    </>
                                                }}
                                                name={nameof<TourManageModel>('isTopTour')}
                                                control={control}
                                            />

                                            <br />
                                        </FormFieldWrapper>
                                        <WysiwygFieldController
                                            label={Localization.getString('Notes To Agents')}
                                            name={nameof<TourManageModel>('notesToAgents')}
                                            toolTipHtml={
                                                <div>
                                                    <p>{Localization.getString('NotesToAgentTooltip1')}</p>
                                                    <p>{Localization.getString('NotesToAgentTooltip2')}</p>
                                                </div>
                                            }
                                            control={control}
                                        />
                                    </Col>
                                    <Col type="50">
                                        <SelectFormFieldController
                                            name={nameof<TourManageModel>('toursCategories')}
                                            isRequired
                                            label={Localization.getString('Tour Categories')}
                                            isNotSearchable={true}
                                            isMultiple
                                            error={tourCategoriesError}
                                            options={toursCategoriesOptions}
                                            control={control}
                                            rules={{
                                                validate: (value: any) => {
                                                    return value && value.length > 0 ? undefined : "At least one tour category is required"
                                                }
                                            }}
                                        />
                                        <SelectFormFieldController
                                            name={nameof<TourManageModel>('recommendedFor')}
                                            isRequired
                                            label={Localization.getString('Recommended For')}
                                            isNotSearchable={true}
                                            error={recommendedForError}
                                            options={toursRecommendedForOptions}
                                            control={control}
                                        />
                                        <WysiwygFieldController
                                            // subLabel={Localization.getString('SalesSubLabel')}
                                            label={Localization.getString('Sales Description')}
                                            name={nameof<TourManageModel>('salesDescription')}
                                            control={control}
                                        />
                                        <ToggleButtonsSmallFieldController
                                            yesText={
                                                supplier?.isDefaultNotesToClientPostBooking ? (
                                                    Localization.getString('Use defaults')
                                                ) : (
                                                    <NoDefaultValue />
                                                )
                                            }
                                            noText={Localization.getString('Use custom')}
                                            name={nameof<TourManageModel>('isDefaultNotesToClientsPostBooking')}
                                            label={''}
                                            control={control}
                                        />
                                        <WysiwygFieldController
                                            placeholder={supplier?.defaultSettings.notesToClientPostBooking}
                                            disabled={isDefaultNotesToClientsPostBooking}
                                            subLabel={Localization.getString(
                                                'This information goes onto the confirmation voucher',
                                            )}
                                            toolTip={Localization.getString('NoteToClientPostBToolTip')}
                                            label={Localization.getString('Notes To Clients Post Booking')}
                                            name={nameof<TourManageModel>('notesToClientsPostBooking')}
                                            control={control}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className={`tab-content-block ${tab == TourFormTab.Location ? "is-active" : ""}`} data-tab="tab-v02">
                                <Row>
                                    <Col type="50">
                                        <FormFieldWrapper>
                                            <TextFormField
                                                // defaultValue={tour.url}
                                                label={Localization.getString('Timing:')}
                                                subLabel={`Hour of operation`}
                                                register={register}
                                                name={nameof<TourManageModel>('locationTiming')}
                                            />
                                        </FormFieldWrapper>
                                        <SelectFormFieldController
                                            name={nameof<TourManageModel>('cityId')}
                                            label={Localization.getString('City')}
                                            error={errors.cityId ? errors.cityId.message : ''}
                                            options={citiesOptions}
                                            control={control}
                                        />
                                        <FormFieldWrapper>
                                            <label>Location</label>
                                            <span>Lat: {location.lat}</span>&nbsp;&nbsp;&nbsp;
                                            <span>Lng: {location.lng}</span>
                                            <MapsLocationPicker
                                                apiKey={GLOBAL_SETTINGS.GOOGLE_MAPS_API_KEY}
                                                onChange={onSetLocation}
                                                value={location}
                                            />
                                        </FormFieldWrapper>
                                        <TourFormDirections
                                            directions={directions}
                                            tour={tour}
                                            onChange={handleTourDirectionsChange}
                                        />
                                    </Col>
                                    <Col type="50">

                                        <FormFieldWrapper>
                                            <Controller
                                                defaultValue={tour.googlePlacesId}
                                                render={({ field }) => {
                                                    const label = "Google Places Reviews"
                                                    const placeIdLabel = googlePlacesRating
                                                        ? `${googlePlacesRating} stars, ${googlePlacesTotalRatings} ratings`
                                                        : ""
                                                    return <MapsPlaceIdPicker
                                                        label={label}
                                                        placeIdLabel={placeIdLabel}
                                                        apiKey={GLOBAL_SETTINGS.GOOGLE_MAPS_API_KEY}
                                                        value={field.value}
                                                        validationError={errors.googlePlacesId}
                                                        onChange={async (e) => {
                                                            if (e) {
                                                                const res = await apiCallWrapperNoDispatch<Rating>("/api/tours/GooglePlaceRatings?placeId=" + e)
                                                                if (res.IsOk) {
                                                                    field.onChange(e)
                                                                    setValue("googlePlacesRating", res.Content?.rating)
                                                                    setValue("googlePlacesTotalRatings", res.Content?.ratingsCount)
                                                                }
                                                                else {
                                                                    setError("googlePlacesId", { type: "custom", message: `Couldn't get rating for place id: ` + res.ErrorTitle })
                                                                }
                                                            }
                                                            else {
                                                                field.onChange(e)
                                                                setValue("googlePlacesRating", undefined)
                                                                setValue("googlePlacesTotalRatings", undefined)
                                                            }
                                                        }}
                                                    />
                                                }}
                                                name={nameof<TourManageModel>('googlePlacesId')}
                                                control={control}
                                            />

                                            <br />
                                        </FormFieldWrapper>
                                        {/* <TextFormField
                                    // defaultValue={tour.url}
                                    label={'Facebook Page Url'}
                                    register={register}
                                    name={nameof<TourManageModel>('facebookPageId')}
                                /> */}
                                        <FormFieldWrapper>
                                            <Controller
                                                defaultValue={tour.tripAdvisorId}
                                                render={({ field }) => {
                                                    const label = "TripAdvisor Reviews"
                                                    const placeIdLabel = tripAdvisorRating
                                                        ? `${tripAdvisorRating} stars, ${tripAdvisorTotalRatings} ratings`
                                                        : ""
                                                    return <TripAdvisorLocationIdPicker
                                                        label={label}
                                                        placeIdLabel={placeIdLabel}
                                                        value={field.value}
                                                        validationError={errors.tripAdvisorId}
                                                        onChange={async (e) => {
                                                            if (e) {
                                                                const res = await apiCallWrapperNoDispatch<Rating>("/api/tours/TripAdvisorLocationRating?locationId=" + e)
                                                                if (res.IsOk && res.Code != 404) {
                                                                    field.onChange(e)
                                                                    setValue("tripAdvisorRating", res.Content?.rating)
                                                                    setValue("tripAdvisorTotalRatings", res.Content?.ratingsCount)
                                                                }
                                                                else {
                                                                    setError("tripAdvisorId", { type: "custom", message: `Couldn't get rating for specified TripAdvisor location: ` + res.ErrorTitle })
                                                                }
                                                            }
                                                            else {
                                                                field.onChange(e)
                                                                setValue("tripAdvisorRating", undefined)
                                                                setValue("tripAdvisorTotalRatings", undefined)
                                                            }
                                                        }}
                                                    />
                                                }}
                                                name={nameof<TourManageModel>('tripAdvisorId')}
                                                control={control}
                                            />

                                            <br />
                                        </FormFieldWrapper>
                                    </Col>

                                </Row>
                            </div>
                            <div className={`tab-content-block ${tab == TourFormTab.Terms ? "is-active" : ""}`} data-tab="tab-v03">
                                <Row>
                                    <Col type="50">
                                        <ToggleButtonsSmallFieldController
                                            yesText={
                                                supplier?.cancellationPolicy && supplier.cancellationPolicy.length > 0 ? (
                                                    Localization.getString('Use defaults')
                                                ) : (
                                                    <NoDefaultValue />
                                                )
                                            }
                                            noText={Localization.getString('Use custom')}
                                            name={nameof<TourManageModel>('isDefaultCancellationPolicy')}
                                            label={''}
                                            control={control}
                                            onChange={(value) => {
                                                if (value) {
                                                    setCancellation(supplier?.cancellationPolicy || [])
                                                }
                                                return value
                                            }}
                                        />
                                        <Cancellations
                                            onChange={(cancellations: CancellationPolicy[]) => {
                                                setValue('isDefaultCancellationPolicy', false)
                                                setCancellation(cancellations)
                                            }}
                                            cancellations={cancellation}
                                        />
                                    </Col>
                                    <Col type='50'>
                                        <ToggleButtonsSmallFieldController
                                            yesText={
                                                supplier?.defaultSettings.termsAndConditions &&
                                                    supplier.defaultSettings.termsAndConditions.length > 0 ? (
                                                    Localization.getString('Use defaults')
                                                ) : (
                                                    <NoDefaultValue />
                                                )
                                            }
                                            noText={Localization.getString('Use custom')}
                                            name={nameof<TourManageModel>('isDefaultTermsAndConditions')}
                                            label={''}
                                            control={control}
                                        />
                                        <WysiwygFieldController
                                            toolTipHtml={
                                                <div>
                                                    <p>{Localization.getString('TermsAndConditionsToolTip1')}</p>
                                                    <p>{Localization.getString('TermsAndConditionsToolTip2')}</p>
                                                    <p>{Localization.getString('TermsAndConditionsToolTip3')}</p>
                                                </div>
                                            }
                                            label={Localization.getString('Terms & Conditions')}
                                            name={nameof<TourManageModel>('termsAndConditions')}
                                            control={control}
                                            disabled={isDefaultTermsAndConditions}
                                            placeholder={supplier?.defaultSettings.termsAndConditions}
                                        />
                                    </Col>
                                </Row>

                            </div>
                            <div className={`tab-content-block ${tab == TourFormTab.Media ? "is-active" : ""}`} data-tab="tab-v04">
                                <Row>
                                    <Col type='50'>
                                        <TextFormField
                                            subLabel={Localization.getString("Please share youtube or vimeo URL's only")}
                                            label={Localization.getString('Video Url')}
                                            register={register}
                                            name={nameof<TourManageModel>('videoUrl')}
                                        />
                                        <VideoDisplay url={videoUrl} />
                                    </Col>
                                    <Col type='50'>
                                        <MediaSelector
                                            heroImageId={heroImageId}
                                            onHeroImageChanged={onHeroImageChanged}
                                            selected={photos}
                                            onSelectPhotos={onSelectPhotos}
                                            onDelete={onDeletePhoto}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className={`tab-content-block ${tab == TourFormTab.Itinerary ? "is-active" : ""}`} data-tab="tab-v05">
                                <Row>
                                    <Col type="50">
                                        <TourItineraryForm
                                            tourId={tour?.id ?? 0}
                                            itineraries={itineraries}
                                            onAddEditItinerary={onAddEditItinerary}
                                            onChange={(itineraries: ItineraryModel[]) => setItineraries(itineraries)}
                                        />
                                    </Col>
                                    <Col type="50">
                                        <WysiwygFieldController
                                            label={Localization.getString('Tour Itinerary')}
                                            name={nameof<TourManageModel>('tourItinerary')}
                                            control={control}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className={`tab-content-block ${tab == TourFormTab.Faq ? "is-active" : ""}`} data-tab="tab-v06">
                                <Row>
                                    <Col type="50">
                                        <TourFormFaq
                                            faq={faq}
                                            onChange={handleFaqChanges}
                                            tour={tour}
                                        />
                                    </Col>
                                    <Col type="50">
                                        <ToggleButtonsSmallFieldController
                                            yesText={
                                                supplier?.defaultSettings.faq && supplier.defaultSettings.faq.length > 0 ? (
                                                    Localization.getString('Use defaults')
                                                ) : (
                                                    <NoDefaultValue />
                                                )
                                            }
                                            noText={Localization.getString('Use custom')}
                                            name={nameof<TourManageModel>('isDefaultFaq')}
                                            label={''}
                                            control={control}
                                        />
                                        <WysiwygFieldController
                                            label={Localization.getString('FAQ')}
                                            toolTip={Localization.getString('FAQSubLabel')}
                                            name={nameof<TourManageModel>('faq')}
                                            disabled={isDefaultFaq}
                                            control={control}
                                            placeholder={supplier?.defaultSettings.faq}
                                        />

                                    </Col>
                                </Row>
                            </div>
                            <div className={`tab-content-block ${tab == TourFormTab.Seo ? "is-active" : ""}`} data-tab="tab-v07">
                                <Row>
                                    <Col type="50">
                                        <TextFormField
                                            label={Localization.getString('SEO Page Title')}
                                            register={register}
                                            name={nameof<TourManageModel>('seoPageTitle')}
                                        />
                                        <SeoLen title={seoPageTitle} type='Title' />
                                        <TextFormField
                                            label={Localization.getString('SEO Page Description')}
                                            register={register}
                                            name={nameof<TourManageModel>('seoPageDescription')}
                                        />
                                        <SeoLen title={seoPageDescr} type='Description' />
                                    </Col>
                                    <Col type="50">
                                        <WysiwygFieldController
                                            label={Localization.getString('SEO Text')}
                                            name={nameof<TourManageModel>('seo')}
                                            control={control}
                                        />
                                        <SeoLen title={seo} type='Other' />
                                    </Col>
                                </Row>
                            </div>
                            <div className={`tab-content-block ${tab == TourFormTab.Tickets ? "is-active" : ""}`} data-tab="tab-v08">
                                <Row>
                                    <Col type="100">
                                        <DataTable data={offers} dataTable={initOffersDataTable()} />
                                    </Col>
                                </Row>
                            </div>


                            <Buttons.StickySaveButton>{Localization.getString('Save')}</Buttons.StickySaveButton>
                        </form>
                        {iteneraryEdit && <Popup onClose={() => setIteneraryEdit(undefined)}>
                            <ItineraryDesigner
                                itinerary={iteneraryEdit}
                                onChange={onChangeItinerary}
                            />
                        </Popup>}
                    </React.Fragment>

                ) : (
                    <LoadingSection />
                )}
            </div>
        </div >
    )
}
export default TourForm
